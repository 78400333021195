/* You can add global styles to this file, and also import other style files */
@charset "UTF-8";
/* CSS Document */

@font-face {
    font-family: "Lato-Black";
    src:
        url("./assets/css/fonts/Lato-Black.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-Black.woff'),
        url('./assets/css/fonts/Lato-Black.eot'),
        url('./assets/css/fonts/Lato-Black.otf') format("opentype"),
        url('./assets/css//fonts/Lato-Black.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-Black.svg#Lato-Black") format("svg");
}

@font-face {
    font-family: "Lato-BlackItalic";
    src:
        url("./assets/css/fonts/Lato-BlackItalic.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-BlackItalic.woff'),
        url('./assets/css/fonts/Lato-BlackItalic.eot'),
        url('./assets/css/fonts/Lato-BlackItalic.otf') format("opentype"),
        url('./assets/css//fonts/Lato-BlackItalic.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-BlackItalic.svg#Lato-BlackItalic") format("svg");
}

@font-face {
    font-family: "Lato-Bold";
    src:
        url("./assets/css/fonts/Lato-Bold.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-Bold.woff'),
        url('./assets/css/fonts/Lato-Bold.eot'),
        url('./assets/css/fonts/Lato-Bold.otf') format("opentype"),
        url('./assets/css//fonts/Lato-Bold.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-Bold.svg#Lato-Bold") format("svg");
}

@font-face {
    font-family: "Lato-BoldItalic";
    src:
        url("./assets/css/fonts/Lato-BoldItalic.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-BoldItalic.woff'),
        url('./assets/css/fonts/Lato-BoldItalic.eot'),
        url('./assets/css/fonts/Lato-BoldItalic.otf') format("opentype"),
        url('./assets/css//fonts/Lato-BoldItalic.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-BoldItalic.svg#Lato-BoldItalic") format("svg");
}

@font-face {
    font-family: "Lato-Italic";
    src:
        url("./assets/css/fonts/Lato-Italic.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-Italic.woff'),
        url('./assets/css/fonts/Lato-Italic.eot'),
        url('./assets/css/fonts/Lato-Italic.otf') format("opentype"),
        url('./assets/css//fonts/Lato-Italic.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-Italic.svg#Lato-Italic") format("svg");
}

@font-face {
    font-family: "Lato-Light";
    src:
        url("./assets/css/fonts/Lato-Light.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-Light.woff'),
        url('./assets/css/fonts/Lato-Light.eot'),
        url('./assets/css/fonts/Lato-Light.otf') format("opentype"),
        url('./assets/css//fonts/Lato-Light.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-Light.svg#Lato-Light") format("svg");
}

@font-face {
    font-family: "Lato-LightItalic";
    src:
        url("./assets/css/fonts/Lato-LightItalic.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-LightItalic.woff'),
        url('./assets/css/fonts/Lato-LightItalic.eot'),
        url('./assets/css/fonts/Lato-LightItalic.otf') format("opentype"),
        url('./assets/css//fonts/Lato-LightItalic.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-LightItalic.svg#Lato-LightItalic") format("svg");
}

@font-face {
    font-family: "Lato-Regular";
    src:
        url("./assets/css/fonts/Lato-Regular.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-Regular.woff'),
        url('./assets/css/fonts/Lato-Regular.eot'),
        url('./assets/css/fonts/Lato-Regular.otf') format("opentype"),
        url('./assets/css//fonts/Lato-Regular.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-Regular.svg#Lato-Regular") format("svg");
}

@font-face {
    font-family: "Lato-Thin";
    src:
        url("./assets/css/fonts/Lato-Thin.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-Thin.woff'),
        url('./assets/css/fonts/Lato-Thin.eot'),
        url('./assets/css/fonts/Lato-Thin.otf') format("opentype"),
        url('./assets/css//fonts/Lato-Thin.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-Thin.svg#Lato-Thin") format("svg");
}

@font-face {
    font-family: "Lato-ThinItalic";
    src:
        url("./assets/css/fonts/Lato-ThinItalic.ttf") format("truetype"),
        url('./assets/css/fonts/Lato-ThinItalic.woff'),
        url('./assets/css/fonts/Lato-ThinItalic.eot'),
        url('./assets/css/fonts/Lato-ThinItalic.otf') format("opentype"),
        url('./assets/css//fonts/Lato-ThinItalic.woff2') format('woff2'),
        url("./assets/css/fonts/Lato-ThinItalic.svg#Lato-Thin") format("svg");
}



@font-face {
    font-family: "FontAwesome";
    src:
        url("./assets/css/fonts/fa-solid-900.ttf") format("truetype"),
        url('./assets/css/fonts/fa-solid-900.woff'),
        url('./assets/css/fonts/fa-solid-900.eot'),
        url('./assets/css/fonts/fa-solid-900.otf') format("opentype"),
        url('./assets/css//fonts/fa-solid-900.woff2') format('woff2'),
        url("./assets/css/fonts/fa-solid-900.svg#fa-solid-900") format("svg");
}

* {

    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img,
iframe {
    width: 100%;
    display: block;
}

a {
    text-decoration: none;
    cursor: pointer;

}

a:hover {
    color: #005B90;

}

body {
    font-family: "Lato-Regular", sans-serif;
    font-size: .8rem;
    color: #525252;
    margin: 0;
    padding: 0;


}

ul li,
table tr th,
table tr td {
    list-style: none;
    font-size: 1rem;
}

ol li {
    font-size: 1rem;
}

h1 {
    font-size: 1.6rem;

}

h2,
button {
    font-size: 1.5rem;

}

h4 {
    font-size: 1.1rem;
    letter-spacing: 1px;
}

h5 {
    font-size: 1rem;
}

p {
    font-size: 1rem;
    padding-bottom: 1rem;
    letter-spacing: 1px;
    line-height: 1.4rem;
}

.lineheight {
    line-height: 2.5rem;

}

.small {
    font-size: .8rem;
    line-height: 1rem;
}

.regular {
    font-family: "Lato-Regular", sans-serif;
}

.light {
    font-family: "Lato-Light", sans-serif;
}

.bold,
strong {
    font-family: "Lato-Bold", sans-serif;
}

.blackitalic {
    font-family: 'Lato-BlackItalic', sans-serif;
}

.black {
    font-family: 'Lato-Black', sans-serif;
}

.italic {
    font-family: "Lato-Italic", sans-serif;
}

.tipo-main-color {
    color: #525252;
}

.tipo-second-color {
    color: #005B90;
}

.tipo-white-color {
    color: #fff;
}

.tipo-orange-color {
    color: #E84E1C;
}

.back-light-blue-color {
    background-color: #E9F6FF;
}

.back-blue-color {
    background-color: #005B90;
}

.back-white-color {
    background-color: #fff;
}

.center {
    text-align: center;
}

.desktop {
    display: none;
}

.inlineflex {
    display: inline-flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
}

.flex {
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    column-gap: 2em;
}

.maxwidth {
    max-width: 1600px;
    margin: 0 auto;
}


// estilos comunes en todas las páginas menos home
.cabecera {
    position: relative;

}

.titulo_seccion {
    position: absolute;
    width: 96%;
    margin: 2%;
    padding: 0 0 0 4%;
    bottom: 2%;
    text-transform: uppercase;
    text-shadow: 2px 2px 10px #fff;
}

.contenido {
    padding: 4% 8%;
}

@media only screen and (min-width: 768px) {

    h1 {
        font-size: 2rem;
    }

    h2,
    button {
        font-size: 1.8rem;
    }

    h4 {
        font-size: 1.5rem;
    }

    h5 {
        font-size: 1.3rem;
    }

    p,
    ul li,
    table tr th,
    table tr td,
    label,
    ol li {
        font-size: 1.2rem;
        line-height: 1.8rem;
    }

    .contenido {
        padding: 2% 10%;
    }

    .titulo_seccion {
        padding: 0 0 0 8%;
    }

}


@media only screen and (min-width:1024px) {

    .desktop {
        display: block;
    }

    .movil {
        display: none;
    }

    h1 {
        font-size: 2.2rem;
    }

    p,
    ul li,
    table tr th,
    table tr td,
    ol li {
        font-size: 1.1rem;
    }

    // estilos comunes en todas las páginas menos home
    .contenido {
        padding: 2% 4%;
    }

    .cabecera_contenido {
        display: inline-flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .cabecera_contenido img {
        width: 50% !important;
    }

    .cabecera_contenido .intro {
        width: 50%;
        padding-left: 1%;
    }

    .titulo_seccion {
        padding: 0 0 0 4%;
    }

}

@media only screen and (min-width:1500px) {

    h1 {
        font-size: 3rem;
    }

    h2,
    button {
        font-size: 2rem;
    }

    // estilos comunes en todas las páginas menos home

    .contenido {
        padding: 2%;
    }

    .titulo_seccion {
        padding: 0 0 0 14%;
    }


}